import { SettingsName, SettingsSection, Tracker } from '../tracker.type';
import { defineStorageMethod } from '@lo/shared/helpers/defineStorageMethod';
import { GET_FROM_STORAGE } from '@lo/shared/constants';
import { getBooleanFromString } from '@lo/shared/helpers/string/getBooleanFromString';
import { OrderData } from '@lo/shared/types/orderDataType';
import { DateRange, getDateRangeName } from '@lo/shared/helpers/dates';
import { OrderHistoryFilters } from '@lo/shared/store/orderHistory';
import {
    Context,
    createComponentContext,
    createFilterContext,
    createFilterContextsForOrderHistory,
    createGlobalContexts,
    createOrderContext,
    createProductContext,
    createSettingContext,
    createTimeContext
} from './contexts';
import { OrderHistoryDataItem } from '../../../types/orderHistoryType';

export default class Snowplow implements Tracker {
    private experiments: Record<string, string> = {};

    private consentIsGiven() {
        return getBooleanFromString(defineStorageMethod(GET_FROM_STORAGE, 'cookieBannerWasSeen'));
    }

    private trackSelfDescribingEvent(schema: string, contexts: Context[] = []) {
        if (!this.consentIsGiven()) return;

        window.analytics_pipeline?.('trackSelfDescribingEvent', {
            event: { schema, data: {} },
            context: [...createGlobalContexts(this.experiments), ...contexts]
        });
        window.analytics_pipeline?.('flushBuffer');
    }

    viewedPage() {
        if (!this.consentIsGiven()) return;

        window.analytics_pipeline?.('trackPageView', { context: createGlobalContexts(this.experiments) });
        window.analytics_pipeline?.('flushBuffer');
    }

    experimentActive(name: string, variant: string) {
        const alreadyExists = this.experiments.hasOwnProperty(name);
        this.experiments[name] = variant;

        if (!this.consentIsGiven()) return;

        if (!alreadyExists) {
            this.trackSelfDescribingEvent('iglu:jet/experiment_active/jsonschema/1-0-0');
        }
    }

    // Orders

    orders = {
        confirmedOrder: (order: OrderData) => {
            this.trackSelfDescribingEvent('iglu:jet.part/order_accept/jsonschema/1-0-0', [createOrderContext(order)]);
        },

        movedOrderToInDelivery: (order: OrderData) => {
            this.trackSelfDescribingEvent('iglu:jet.part/order_onItsWay/jsonschema/1-0-0', [createOrderContext(order)]);
        },

        deliveredOrder: (order: OrderData) => {
            this.trackSelfDescribingEvent('iglu:jet.part/order_done/jsonschema/1-0-0', [createOrderContext(order)]);
        },

        movedOrderBackToKitchen: (order: OrderData) => {
            this.trackSelfDescribingEvent('iglu:jet.part/order_backToKitchen/jsonschema/1-0-0', [createOrderContext(order)]);
        },

        changedOrderDuration: (type: 'cook' | 'delivery', amount: number, order: OrderData) => {
            this.trackSelfDescribingEvent('iglu:jet.part/order_timeUpdate/jsonschema/1-0-0', [
                createOrderContext(order),
                createTimeContext(type, amount)
            ]);
        },

        updatedConfirmedOrder: (order: OrderData) => {
            this.trackSelfDescribingEvent('iglu:jet.part/order_update/jsonschema/1-0-0', [createOrderContext(order)]);
        },

        viewedOrderTabsInfo: () => {
            this.trackSelfDescribingEvent('iglu:jet/dialog_view/jsonschema/1-0-0', [
                createComponentContext('order_tab_tutorial', 'dialog')
            ]);
        },

        selectedOrdersTab: (tab: 'prepare' | 'handover' | 'done') => {
            this.trackSelfDescribingEvent('iglu:jet.part/orderTab_select/jsonschema/1-0-0', [
                createComponentContext(tab, 'header', { position: 2 })
            ]);
        },

        openedScheduledOrders: () => {
            this.trackSelfDescribingEvent('iglu:jet.part/scheduledOrder_select/jsonschema/1-0-0');
        },

        closedScheduledOrders: () => {
            this.trackSelfDescribingEvent('iglu:jet.part/scheduledOrder_close/jsonschema/1-0-0');
        },

        openedCancelledOrders: () => {
            this.trackSelfDescribingEvent('iglu:jet.part/cancelledOrder_select/jsonschema/1-0-0');
        },

        closedCancelledOrders: () => {
            this.trackSelfDescribingEvent('iglu:jet.part/cancelledOrder_close/jsonschema/1-0-0');
        }
    };

    // Menu

    menu = {
        productIsOutOfStock: (productId: string, categoryId: string, backToStockAt?: Date) => {
            const contexts = [createProductContext(productId, categoryId)];

            if (backToStockAt) {
                contexts.push({
                    schema: 'iglu:jet.part/cx_response/jsonschema/1-0-1',
                    data: { date: backToStockAt }
                });
            }

            this.trackSelfDescribingEvent('iglu:jet.part/item_offline/jsonschema/1-0-0', contexts);
        },

        productIsBackInStock: (productId: string, categoryId: string) => {
            this.trackSelfDescribingEvent('iglu:jet.part/item_online/jsonschema/1-0-0', [
                createProductContext(productId, categoryId)
            ]);
        },

        allProductsAreBackInStock: (productIds: string[]) => {
            const productContexts = productIds.map((id) => createProductContext(id));
            this.trackSelfDescribingEvent('iglu:jet.part/item_allOnline/jsonschema/1-0-0', productContexts);
        },

        scrolledToTop: () => {
            this.trackSelfDescribingEvent('iglu:jet/navigation_select/jsonschema/1-0-0');
        },

        clickedOnSearch: () => {
            this.trackSelfDescribingEvent('iglu:jet.part/searchMenu_access/jsonschema/1-0-0');
        },

        clearedSearch: (query: string) => {
            this.trackSelfDescribingEvent('iglu:jet.part/searchMenu_clear/jsonschema/1-0-0', [
                {
                    schema: 'iglu:jet.part/cx_query/jsonschema/1-0-0',
                    data: { value: query.length }
                }
            ]);
        },

        clickedOnShowMoreResults: (query: string) => {
            this.trackSelfDescribingEvent('iglu:jet.part/searchMenu_accessAllResults/jsonschema/1-0-0', [
                {
                    schema: 'iglu:jet.part/cx_query/jsonschema/1-0-0',
                    data: { value: query.length }
                }
            ]);
        },

        clickedOnSearchListItem: (query: string) => {
            this.trackSelfDescribingEvent('iglu:jet.part/searchMenu_accessPreliminaryList/jsonschema/1-0-0', [
                {
                    schema: 'iglu:jet.part/cx_query/jsonschema/1-0-0',
                    data: { value: query.length }
                }
            ]);
        },

        turnedOnOutOfStockFilter: () => {
            this.trackSelfDescribingEvent('iglu:jet/filter_add/jsonschema/1-0-1', [
                createFilterContext('out_of_stock_filter', 'on')
            ]);
        },

        turnedOffOutOfStockFilter: (element: 'button' | 'toggle') => {
            this.trackSelfDescribingEvent('iglu:jet/filter_remove/jsonschema/1-0-1', [
                createFilterContext('out_of_stock_filter', 'on'),
                createComponentContext('out_of_stock_filter', element)
            ]);
        },

        selectedCategory: (categoryId: string) => {
            this.trackSelfDescribingEvent('iglu:jet.part/menuCategory_select/jsonschema/1-0-0', [
                {
                    schema: 'iglu:jet/cx_productCategory/jsonschema/1-0-0',
                    data: { id: categoryId }
                }
            ]);
        }
    };

    orderHistory = {
        openedOrder: (order: OrderHistoryDataItem | OrderData) => {
            this.trackSelfDescribingEvent('iglu:jet.part/orderModal_view/jsonschema/1-0-0', [createOrderContext(order)]);
        },

        closedOrder: (order: OrderHistoryDataItem | OrderData) => {
            this.trackSelfDescribingEvent('iglu:jet.part/orderModal_dismiss/jsonschema/1-0-0', [createOrderContext(order)]);
        },

        appliedDateRange: (dateRange: DateRange) => {
            this.trackSelfDescribingEvent('iglu:jet.part/orderHistoryFilter_add/jsonschema/1-0-0', [
                createFilterContext('date_range_filter', getDateRangeName(dateRange) ?? 'custom'),
                {
                    schema: 'iglu:jet/cx_dateRange/jsonschema/1-0-1',
                    data: { date_start: dateRange[0], date_end: dateRange[1] }
                }
            ]);
        },

        appliedFilters: (filters: OrderHistoryFilters) => {
            this.trackSelfDescribingEvent(
                'iglu:jet.part/orderHistoryFilter_add/jsonschema/1-0-0',
                createFilterContextsForOrderHistory(filters)
            );
        },

        removedFilter: (
            filter: 'order_status_filter' | 'payment_method_filter' | 'date_range_filter',
            value: string,
            component?: string
        ) => {
            this.trackSelfDescribingEvent('iglu:jet.part/orderHistoryFilter_remove/jsonschema/1-0-0', [
                createFilterContext(filter, value),
                createComponentContext(component ?? value)
            ]);
        },

        removedAllFilters: (filters: OrderHistoryFilters, dateRange?: DateRange, component?: string) => {
            const contexts = createFilterContextsForOrderHistory(filters, dateRange);

            if (component) {
                contexts.push(createComponentContext(component));
            }

            this.trackSelfDescribingEvent('iglu:jet.part/orderHistoryFilter_removeAll/jsonschema/1-0-0', contexts);
        },

        exported: (type: 'print' | 'pdf' | 'csv') => {
            this.trackSelfDescribingEvent('iglu:jet.part/orderHistory_export/jsonschema/1-0-0', [createComponentContext(type)]);
        }
    };

    settings = {
        toggled: (section: SettingsSection, name: SettingsName, value: boolean) => {
            this.trackSelfDescribingEvent(`iglu:jet.part/settings_${value ? 'enable' : 'disable'}/jsonschema/1-0-0`, [
                createSettingContext(section, name)
            ]);
        },

        changedSound: (value: string) => {
            this.trackSelfDescribingEvent('iglu:jet.part/settings_update/jsonschema/1-0-0', [
                createSettingContext('sound', 'name', value)
            ]);
        },

        changedDuration: (type: 'cook' | 'delivery', value: number) => {
            this.trackSelfDescribingEvent('iglu:jet.part/settings_update/jsonschema/1-0-0', [createTimeContext(type, value)]);
        }
    };
}
