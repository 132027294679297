import i18n from '@lo/shared/localization/i18n';
import config from '@lo/shared/helpers/config';
import queryClient from '@lo/shared/services/query/queryClient';
import { RestaurantData } from '@lo/shared/types/restaurantDataType';
import { OrderData } from '@lo/shared/types/orderDataType';
import { DateRange, getDateRangeName, isSameDateRange } from '../../../helpers/dates';
import { OrderHistoryFilters } from '../../../store/orderHistory';
import { OrderHistoryDataItem } from '../../../types/orderHistoryType';

export type Context = {
    schema: string;
    data: Record<string, any>;
};

const availableCountries = [
    'gb',
    'uk',
    'au',
    'be',
    'bg',
    'dk',
    'fr',
    'de',
    'it',
    'nl',
    'no',
    'pl',
    'pt',
    'ro',
    'es',
    'ch',
    'sk',
    'xx',
    'all'
];

export function createGlobalContexts(activeExperiments: Record<string, string>) {
    const environment = config.env === 'production' ? 'prod' : config.env === 'staging' ? 'stag' : 'dev';
    const language = i18n.language?.slice(0, 2) || 'en';
    const version = config.release?.slice(0, 36) || '0.0.0'; // Max allowed length of version is 36 characters
    const restaurantData = queryClient.getQueryData<RestaurantData>(['restaurant']);

    let country = restaurantData?.country_contact_information.code || '';
    country = availableCountries.includes(country) ? country : 'xx';

    const contexts: Context[] = [
        {
            schema: 'iglu:jet/cx_platform/jsonschema/1-1-1',
            data: { language, country, version, environment }
        },
        {
            schema: 'iglu:jet/cx_consent/jsonschema/1-0-0',
            data: { status: ['full'] }
        },
        {
            schema: 'iglu:jet/cx_producer/jsonschema/1-0-1',
            data: { team: 'alpha-team' }
        }
    ];

    Object.entries(activeExperiments).forEach(([name, variant]) => {
        contexts.push({
            schema: 'iglu:jet/cx_experiment/jsonschema/1-0-0',
            data: {
                name,
                variant,
                platform: 'feature management'
            }
        });
    });

    if (restaurantData) {
        contexts.push({
            schema: 'iglu:jet.part/cx_partner/jsonschema/1-0-0',
            data: {
                id: restaurantData.reference,
                status: restaurantData.is_open ? 'open' : 'closed'
            }
        });
    }

    return contexts;
}

export function createOrderContext(order: OrderData | OrderHistoryDataItem): Context {
    return {
        schema: 'iglu:jet.part/cx_order/jsonschema/1-0-0',
        data: {
            id: order.public_reference,
            total: order.restaurant_total,
            status: order.status,
            placedDateTime: order.placed_date
        }
    };
}

export function createComponentContext(name: string, type = 'button', extraData = {}): Context {
    return {
        schema: 'iglu:jet/cx_component/jsonschema/1-0-0',
        data: { name, type, ...extraData }
    };
}

export function createFilterContext(name: string, value: string): Context {
    return {
        schema: 'iglu:jet/cx_filter/jsonschema/1-0-0',
        data: { name, value }
    };
}

export function createProductContext(productId: string, categoryId?: string): Context {
    return {
        schema: 'iglu:jet.part/cx_item/jsonschema/1-0-0',
        data: { id: productId, categoryId: categoryId ?? null }
    };
}

export function createFilterContextsForOrderHistory(filters: OrderHistoryFilters, dateRange?: DateRange): Context[] {
    const filtersData: { name: string; value: string }[] = [];

    filters.statuses.forEach((status) => filtersData.push({ name: 'order_status_filter', value: status }));
    filters.paymentMethods.forEach((method) => filtersData.push({ name: 'payment_method_filter', value: method }));

    if (dateRange && !isSameDateRange(dateRange, 'today')) {
        filtersData.push({ name: 'date_range_filter', value: getDateRangeName(dateRange) ?? 'custom' });
    }

    return filtersData.map(({ name, value }) => createFilterContext(name, value));
}

export function createTimeContext(type: 'cook' | 'delivery', amount: number): Context {
    return {
        schema: 'iglu:jet.part/cx_time/jsonschema/1-0-0',
        data: { type, amount }
    };
}

export function createSettingContext(section: string, name: string, type?: string): Context {
    return {
        schema: 'iglu:jet.part/cx_setting/jsonschema/1-0-0',
        data: { section, name, type: type ?? null }
    };
}
